<script>
import QRCode from 'qrcode';

export default {
  props: {
    message: { type: Object, default: null },
    loadingRole: { type: String, default: null },
    initialMessage: { type: Boolean },
    showGreeting: { type: Boolean },
    idleTimerMessage: { type: Boolean },
    showIdleMessage: { type: Boolean },
    showMessageContainsMobileApp: { type: Boolean, default: false },
  },
  data() {
    return {
      dotNumber: 3,
      animationDelay: 450,
      urlObject: {
        app_store_url: '',
        play_store_url: '',
      },
      distinctId: null,
      url: null,
      qrCode: null,
      observer: null,
    };
  },
  computed: {
    computedRole() {
      return this.loadingRole || (this.message ? this.message.role : null);
    },
    totalAnimaitonTime() {
      return this.dotNumber * this.animationDelay;
    },
    messageContent() {
      if (!this.loadingRole) {
        if (this.message && this.message.content) {
          return this.message.content;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    roleTag() {
      if (this.computedRole === 'assistant') {
        return this.$t('bot.chat.botTag');
      }
      return this.$t('bot.chat.userTag');
    },
    isUser() {
      return this.computedRole === 'user';
    },
    isAssistant() {
      return this.computedRole === 'assistant';
    },
    messageContainsRelevantTeam() {
      const message = this.messageContent && this.messageContent.toLowerCase();
      const relevantPhrases = [
        'service clientèle',
        "l'équipe appropriée",
        "l'équipe concernée",
        'relevant team',
      ];

      return message && relevantPhrases.some(phrase => message.includes(phrase));
    },
    messageContainsMobileApp() {
      const message = this.messageContent && this.messageContent.toLowerCase();
      const relevantPhrases = ['mobile app', "l'application mobile", "aplicación móvil"];

      return message && relevantPhrases.some(phrase => message.includes(phrase));
    },
    wasMessageInfoClicked() {
      if (!this.isSessionStorageEnabled()) {
        return;
      }

      return sessionStorage.getItem('wasMessageInfoClicked') === 'true';
    },
  },
  mounted() {
    this.urlObject = JSON.parse(window.url_object?.replace(/&quot;/g, '"'));
    this.distinctId = this.getPosthogDistinctId();
    this.url = window.download_app_qr_code;
    this.generateQRCode();
    this.initMutationObserver();
  },
  beforeUnmount() {
    this.observer?.disconnect();
  },
  beforeDestroy() {
    this.observer?.disconnect();
  },
  methods: {
    removeDuplicateElements() {
      this.$nextTick(() => {
        const elements = document.querySelectorAll('.messageContainsMobileApp');

        elements.forEach((element, index) => {
          if (index > 0) {
            element.parentNode?.removeChild(element);
          }
        });
      });
    },
    initMutationObserver() {
      const targetNode = this.$refs.messageContainsMobileApp;

      if (!targetNode) {
        return;
      }

      const config = { attributes: true, childList: true, subtree: true };

      const callback = (mutationsList, observer) => {
        for (let mutation of mutationsList) {
          if (mutation.type === 'attributes' || mutation.type === 'childList') {
            this.removeDuplicateElements();
          }
        }
      };

      this.observer = new MutationObserver(callback);

      this.observer.observe(targetNode, config);
    },
    async generateQRCode() {
      const dynamicLink = new URL(this.urlObject.url);

      this.qrCode = await QRCode.toDataURL(dynamicLink.toString());
    },
    appDownload(store) {
      appDownload(store);
    },
    getPosthogDistinctId() {
      if (typeof window.posthog?.get_distinct_id !== 'function') {
        return null;
      }
      return window.posthog.get_distinct_id();
    },
    isSessionStorageEnabled() {
      try {
        const key = `__storage__test`;
        window.sessionStorage.setItem(key, null);
        window.sessionStorage.removeItem(key);
        return true;
      } catch (e) {
        return false;
      }
    },
  },
};
</script>

<template>
  <div
    class="d-flex flex-column"
    :class="{
      'align-items-end': isUser,
    }"
  >
    <div class="max-width-75 d-flex">
      <img
        v-if="isAssistant"
        class="d-inline-block text-capitalize dark-grey mb-4 me-8"
        :class="{ 'ms-auto': roleTag === $t('bot.chat.userTag') }"
        :src="require('images/price_bot/supportLogo.png')"
        :alt="$t('bot.title')"
        height="24"
        width="24"
        loading="lazy"
      />
      <p
        class="p-16 rounded-border-8 text-break"
        :class="{
          'bg-sky-blue dark-blue': isUser,
          'bg-tag-grey whitespace-pre-line': isAssistant,
        }"
      >
        <template
          v-if="
            (!initialMessage && !idleTimerMessage && loadingRole) ||
            (initialMessage && !showGreeting) ||
            (idleTimerMessage && !showIdleMessage)
          "
        >
          <div class="loading-dots">
            <span
              class="loading-dot"
              v-for="dotIndex in dotNumber"
              :key="dotIndex"
              :style="{
                animation: `dot-flashing ${totalAnimaitonTime}ms infinite`,
                animationDelay: `${dotIndex * animationDelay}ms`,
              }"
            ></span>
          </div>
        </template>
        <template v-else-if="initialMessage && showGreeting">
          <span :class="{ 'chat-message': isAssistant }">
            {{ $t('bot.chat.initialMessage') }}
          </span>
        </template>
        <template v-else-if="idleTimerMessage && showIdleMessage">
          <span :class="{ 'chat-message': isAssistant }">
            {{ $t('bot.chat.idleMessage') }}
          </span>
        </template>
        <template v-else>
          <span :class="{ 'chat-message': isAssistant }">
            {{ messageContent }}
          </span>
        </template>
      </p>
    </div>
    <div
      v-if="
        (messageContainsMobileApp && isAssistant) ||
        showMessageContainsMobileApp ||
        wasMessageInfoClicked
      "
      ref="messageContainsMobileApp"
      class="messageContainsMobileApp max-width-75 d-flex mt-16"
    >
      <img
        class="d-inline-block text-capitalize dark-grey mb-4 me-8"
        :class="{ 'ms-auto': roleTag === $t('bot.chat.userTag') }"
        :src="require('images/price_bot/supportLogo.png')"
        :alt="$t('bot.title')"
        height="24"
        width="24"
        loading="lazy"
      />
      <div class="border-radius-4 p-16 bg-sky-blue">
        <div class="heading mb-16 d-flex flex-row align-items-center w-100">
          <span class="d-block dark-blue fw-bold font-18-20 d-md-none">{{
            $t('bot.get_the_app')
          }}</span>
          <span class="d-block dark-blue fw-bold font-18-20 d-none d-md-block">{{
            $t('bot.scan_to_get_the_app')
          }}</span>
        </div>
        <div class="content d-flex w-100 flex-wrap d-md-none">
          <div class="store-links d-flex-flex-row">
            <a
              @click.stop="appDownload('iOS')"
              :href="urlObject.app_store_url"
              target="_blank"
              rel="noopener"
            >
              <img
                :src="require('images/app_stores/app_store2x.png')"
                width="120"
                height="37"
                class="me-5"
              />
            </a>
            <a
              @click.stop="appDownload('android')"
              :href="urlObject.play_store_url"
              target="_blank"
              rel="noopener"
            >
              <img :src="require('images/app_stores/play_store2x.png')" width="120" height="37" />
            </a>
          </div>
        </div>
        <div
          ref="qrCodeContainer"
          class="qr-code-chat border-radius-8 background-white p-8 d-md-block d-none"
        >
          <img v-if="qrCode" :src="qrCode" width="200" height="200" />
        </div>
      </div>
    </div>

    <div
      v-if="messageContainsRelevantTeam && isAssistant && !messageContainsMobileApp"
      class="max-width-75 d-flex mt-16"
    >
      <img
        class="d-inline-block text-capitalize dark-grey mb-4 me-8"
        :class="{ 'ms-auto': roleTag === $t('bot.chat.userTag') }"
        :src="require('images/price_bot/supportLogo.png')"
        :alt="$t('bot.title')"
        height="24"
        width="24"
        loading="lazy"
      />
      <p
        class="p-16 rounded-border-8 text-break"
        :class="{
          'white fw-600 bg-primary-blue relevant-team-link bot_launcher_button': isAssistant,
        }"
        @click="$emit('open-support-request')"
      >
        {{ $t('bot.contectToSpecialist') }}
      </p>
    </div>
  </div>
</template>
